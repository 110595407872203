"use strict";
// export const PortalURLs_2023 = {
//   CORE_URLS: {
//     STATE:
//       "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2023/FeatureServer/229",
//     CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/CD_Core_2023/FeatureServer/256",
//     SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/273",
//     SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/272",
//   },
Object.defineProperty(exports, "__esModule", { value: true });
exports.PortalURLs = exports.grantURLs = exports.portalURLs = void 0;
//   COUNTY_URLS: {
//     STATE:
//       "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/State_Core_2023/FeatureServer/288",
//     CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/CD_Core_2023/FeatureServer/287",
//     SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/276",
//     SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/275",
//   },
//   AGENCY_URLS: {
//     STATE:
//       "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/State_Core_2023/FeatureServer/287",
//     CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/CD_Core_2023/FeatureServer/286",
//     SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/275",
//     SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/274",
//   },
//   GRANTS_URLS: {
//     STATE:
//       "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/State_Core_2023/FeatureServer/286",
//     CD: "https://services2.arcgis.com/MGS8P397fp3TUR2w/ArcGIS/rest/services/CD_Core_2023/FeatureServer/285",
//     SLDL: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDL_Core_2023/FeatureServer/274",
//     SLDU: "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services/SLDU_Core_2023/FeatureServer/273",
//   },
// };
// // 2024 change
// const APTA_FS_URL =
//   "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services";
// export const portalURLs: { [key: string]: string } = {
//   STATE_SUM_CORE: `${APTA_FS_URL}/State_Core_2024/FeatureServer`,
//   SENATE_CORE: `${APTA_FS_URL}/State_Core_2024/FeatureServer`,
//   HOUSE_CORE: `${APTA_FS_URL}/CD_Core_2024/FeatureServer`,
//   SLDL_CORE: `${APTA_FS_URL}/SLDL_Core_2024/FeatureServer`,
//   SLDU_CORE: `${APTA_FS_URL}/SLDU_Core_2024/FeatureServer`,
// };
// export const grantURLs: { [key: string]: string } = {
//   STATE: `${portalURLs.STATE_SUM_CORE}/557/query`,
//   CD: `${portalURLs.HOUSE_CORE}/560/query`,
//   SLDL: `${portalURLs.SLDL_CORE}/558/query`,
//   SLDU: `${portalURLs.SLDU_CORE}/559/query`,
// };
// export const PortalURLs = {
//   CORE_URLS: {
//     STATE: `${portalURLs.STATE_SUM_CORE}/580`,
//     CD: `${portalURLs.HOUSE_CORE}/577`,
//     SLDL: `${portalURLs.SLDL_CORE}/574`,
//     SLDU: `${portalURLs.SLDU_CORE}/556`,
//   },
//   COUNTY_URLS: {
//     STATE: `${portalURLs.STATE_SUM_CORE}/288`,
//     CD: `${portalURLs.HOUSE_CORE}/287`,
//     SLDL: `${portalURLs.SLDL_CORE}/276`,
//     SLDU: `${portalURLs.SLDU_CORE}/275`,
//   },
//   AGENCY_URLS: {
//     STATE: `${portalURLs.STATE_SUM_CORE}/635`,
//     CD: `${portalURLs.HOUSE_CORE}/634`,
//     SLDL: `${portalURLs.SLDL_CORE}/633`,
//     SLDU: `${portalURLs.SLDU_CORE}/632`,
//   },
//   GRANTS_URLS: {
//     STATE: `${portalURLs.STATE_SUM_CORE}/557`,
//     CD: `${portalURLs.HOUSE_CORE}/560`,
//     SLDL: `${portalURLs.SLDL_CORE}/558`,
//     SLDU: `${portalURLs.SLDU_CORE}/559`,
//   },
// };
const APTA_FS_URL = "https://services2.arcgis.com/MGS8P397fp3TUR2w/arcgis/rest/services";
exports.portalURLs = {
    STATE_SUM_CORE: `${APTA_FS_URL}/State_Core_2025/FeatureServer`,
    SENATE_CORE: `${APTA_FS_URL}/State_Core_2025/FeatureServer`,
    HOUSE_CORE: `${APTA_FS_URL}/CD_Core_2025/FeatureServer`,
    SLDL_CORE: `${APTA_FS_URL}/SLDL_Core_2025/FeatureServer`,
    SLDU_CORE: `${APTA_FS_URL}/SLDU_Core_2025/FeatureServer`,
};
exports.grantURLs = {
    STATE: `${exports.portalURLs.STATE_SUM_CORE}/756/query`,
    CD: `${exports.portalURLs.HOUSE_CORE}/767/query`,
    SLDL: `${exports.portalURLs.SLDL_CORE}/759/query`,
    SLDU: `${exports.portalURLs.SLDU_CORE}/758/query`,
};
exports.PortalURLs = {
    CORE_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/706`,
        CD: `${exports.portalURLs.HOUSE_CORE}/705`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/704`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/703`,
    },
    GRANTS_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/756`,
        CD: `${exports.portalURLs.HOUSE_CORE}/767`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/759`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/758`,
    },
    COUNTY_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/755`,
        CD: `${exports.portalURLs.HOUSE_CORE}/763`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/762`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/761`,
    },
    AGENCY_URLS: {
        STATE: `${exports.portalURLs.STATE_SUM_CORE}/757`,
        CD: `${exports.portalURLs.HOUSE_CORE}/766`,
        SLDU: `${exports.portalURLs.SLDU_CORE}/765`,
        SLDL: `${exports.portalURLs.SLDL_CORE}/764`,
    },
};
