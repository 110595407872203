import React, { PropsWithChildren, useMemo } from "react";
import { FedLeg, StateLeg } from "APTAPackage";
import PersonListElem from "../../../components/Pages/Map/Panels/ListElems/Person";
import { sortLegislatorsAlpha } from "../Utils/sortLegislatorsAlpha";
import { sortLegislatorsSeniority } from "../Utils/sortLegislatorsSeniority";

// I would like to extend this function to handle any list, but out of scope for now!
type T_props = PropsWithChildren<{
  sortType?: "ALPHA" | "SENIORITY";
  people: (FedLeg | StateLeg | StateLeg[])[];
}>;

export const PanelLegislatorList: React.FC<T_props> = ({
  people,
  sortType,
  children,
}) => {
  const flatPeople = useMemo(() => {
    return people.flat(Infinity) as (FedLeg | StateLeg)[];
  }, [people]);

  const sortedPeople = useMemo(() => {
    switch (sortType) {
      case "ALPHA": {
        return sortLegislatorsAlpha(flatPeople);
      }
      case "SENIORITY": {
        return sortLegislatorsSeniority(flatPeople);
      }
      case undefined:
        return flatPeople;
      default:
        return flatPeople;
    }
  }, [flatPeople, sortType]);

  return (
    <ul className="gm-person-list">
      {children}
      {sortedPeople.map((person) => {
        const key = person?.identityInfo?.hashID;
        return <PersonListElem key={key} person={person} district={true} />;
      })}
    </ul>
  );
};
