import { FedLeg, StateLeg } from "APTAPackage";

// Determine if the legislator is a federal senator.
function isFed(legislator: FedLeg | StateLeg): legislator is FedLeg {
  return (legislator as FedLeg).state_rank !== undefined;
}

// If State Legislator return -1
// If no seniority found return -1
// If junior return 1
// If senior return 2
function _getSeniorityAsNumber(legislator: FedLeg | StateLeg): number {
  let value: number = -1;

  // Only proceed if Federal Legislator
  if (isFed(legislator) == true) {
    const state_rank = legislator?.state_rank;
    if (state_rank != null) {
      if (state_rank == "junior") value = 1;
      if (state_rank == "senior") value = 2;
    }
  }
  return value;
}

// Use _getSeniority and then do simple compare.
export function sortLegislatorsSeniority(legislators: (FedLeg | StateLeg)[]) {
  if (legislators.length > 2)
    console.error(
      "We currently only support sorting Senators by seniority. You should not be using this function."
    );

  return legislators.sort((a, b) => {
    const rankA = _getSeniorityAsNumber(a);
    const rankB = _getSeniorityAsNumber(b);
    return rankB - rankA;
  });
}
