import React from "react";

import { FederalAppropriationsItem } from "./ FederalAppropriationsItem";

import { AGOLResponse } from "APTAPackage";

import { genMoneyString } from "../../../../components/Pages/Print/helpers";
import { SectionTitle } from "../../Components/SectionTitle";
import { T_dataComponent } from "../../../types";
import LoadingSpinner from "../../../../components/Icons/LoadingSpinner";
import { HomeHeader } from "../../../../components/Pages/Home/components/HomeHeader";

interface IFedApprop {
  data: AGOLResponse;
  dataLoading?: boolean;
  type: T_dataComponent;
}

const FederalAppropriations: React.FC<IFedApprop> = ({
  data,
  type,
  dataLoading,
}) => {
  const thudFunding = genMoneyString(data?.coreData?.sum_formula_funding);
  const caresFunding = genMoneyString(data?.coreData?.sum_cares_funding);
  const fastFunding = genMoneyString(data?.coreData?.sum_fast_funding);

  if (type === "HOME") {
    return (
      <>
        {" "}
        <HomeHeader
          title="Federal Appropriations"
          id="Federal_Appropriations"
        />
        <div>
          {dataLoading ? (
            <LoadingSpinner color="var(--apta-navbar-text-color)" />
          ) : (
            <>
              <FederalAppropriationsItem
                amount={thudFunding}
                title="Bipartisan Infrastructure Law 5-Year Total"
                size="6rem"
                classMod="justify-content-center"
                type={type}
              />
              <FederalAppropriationsItem
                amount={fastFunding}
                title="FAST Act 5 Year Total FY 2016 - FY 2020"
                size="6rem"
                classMod="justify-content-center"
                type={type}
              />
              {/* Jan-14-2025 We removed the Covid data by nulling the content of the item so it stayed inline. */}
              <FederalAppropriationsItem
                amount={""}
                title=""
                size="6rem"
                classMod="justify-content-center"
                type={type}
              />
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <SectionTitle title="Federal Appropriations" type={type}>
      {dataLoading ? (
        <LoadingSpinner color="var(--apta-navbar-text-color)" />
      ) : (
        <>
          <FederalAppropriationsItem
            amount={thudFunding}
            title="Bipartisan Infrastructure Law 5-Year Total"
            size="6rem"
            classMod="justify-content-center"
            type={type}
          />
          <FederalAppropriationsItem
            amount={fastFunding}
            title="FAST Act 5 Year Total FY 2016 - FY 2020"
            size="6rem"
            classMod="justify-content-center"
            type={type}
          />
          {/* Jan-14-2025 We removed the Covid data by nulling the content of the item so it stayed inline. */}
          <FederalAppropriationsItem
            amount={""}
            title=""
            size="6rem"
            classMod="justify-content-center"
            type={type}
          />
        </>
      )}
    </SectionTitle>
  );
};

export default FederalAppropriations;
