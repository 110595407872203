import { FedLeg, StateLeg } from "APTAPackage";

// If lastName exists return it.
// If lastName does not exist try to pull it off of first name
// Return empty string if missing.
function _getLastName(legislator: FedLeg | StateLeg) {
  let value: string = "";
  const lastName = legislator?.lastName;
  const fullName = legislator?.fullName;

  if (lastName != null) value = lastName.trim();
  else if (fullName != null) {
    let lastNameLocation = fullName.lastIndexOf(" ");
    // If it does not exist we cannot find a last name.
    if (lastNameLocation !== -1) {
      lastNameLocation++;

      const parsedLastName = fullName.slice(lastNameLocation);
      if (parsedLastName.length != 0) value = parsedLastName.trim();
    }
  }

  return value;
}

// Use Local Compare on value parsed in _getLastName.
export function sortLegislatorsAlpha(legislators: (FedLeg | StateLeg)[]) {
  return legislators.sort((a, b) => {
    const nameA = _getLastName(a);
    const nameB = _getLastName(b);
    return nameA.localeCompare(nameB);
  });
}
