import { EsriNumber } from "APTAPackage/dist/src/ESRIResponses";

/* 01-15-2025: These functions are used all over the print / explore components in the Features folder, its a bit risky
to move them at the moment, but they should be moved when time allows. */

export function convertNumber(number: EsriNumber) {
  if (number === null || number === undefined) return "No Data";
  // Nine Zeroes for Billions
  return Math.round(Math.abs(number)) >= 1.0e9
    ? Math.round((Math.abs(number) / 1.0e9) * 10) / 10 + "B"
    : // Six Zeroes for Millions
    Math.round(Math.abs(number)) >= 1.0e6
    ? Math.round((Math.abs(number) / 1.0e6) * 10) / 10 + "M"
    : // Three Zeroes for Thousands
    Math.round(Math.abs(number)) >= 1.0e3
    ? Math.round((Math.abs(number) / 1.0e3) * 10) / 10 + "K"
    : Math.round(Math.abs(number) * 10) / 10 + "";
}

export function genMoneyString(value: any | undefined) {
  if (value === undefined || value === "No Data") return "No Data";
  let moneyString = convertNumber(value);
  if (moneyString === "No Data") return moneyString;
  return `$${moneyString}`;
}
