import React from "react";
import { observer } from "mobx-react";
import PanelListElem from "./";
import ToggleChevron from "../../../../Icons/ToggleChevron";
import { useStore } from "../../../../../Hooks";
import { FedLeg, StateLeg, LegislatorTypes } from "APTAPackage";

import CartToggle from "./CartToggle";
import { PersonImage } from "../../../../../_v2/Common/Components/PersonImage";
import {
  sendGAEvent,
  sendGAEventPerson,
} from "../../../../../Hooks/useGoogleAnalytics";

interface PersonListElemProps {
  person: FedLeg | StateLeg;
  solo?: boolean;
  district?: boolean;
  key?: number | string;
}

const PersonListElem: React.FC<PersonListElemProps> = ({
  person,
  solo = false,
  district = false,
}) => {
  const panelStore = useStore("panelStore");

  const titleArr = panelStore.crumbStrings;

  const seniorJuniorRegex = /Senior|senior|Junior|junior/gm;

  // NO SENIOR OR JUNIOR
  if (seniorJuniorRegex.test(person?.title as string)) {
    person = { ...person, title: person?.title.replace(seniorJuniorRegex, "") };
  }

  const onClick = (e: any, person: FedLeg | StateLeg) => {
    e.stopPropagation();
    const { type, hashID, stateAbbrev, chamber } = person.identityInfo;

    sendGAEventPerson({
      eventName: "Click on Person",
      eventLabel: "person_click",
      person,
    });

    if (type === LegislatorTypes.SENATE) {
      return panelStore.renderSenatorPersonPanel(person as FedLeg);
    } else if (type === LegislatorTypes.HOUSE) {
      return panelStore.renderCDPersonPanel(person as FedLeg);
    } else if (type === LegislatorTypes.SLDU || type === LegislatorTypes.SLDL) {
      return panelStore.renderStateLegPersonPanel(person as StateLeg);
    }
  };

  let theDistrict = person?.identityInfo?.district;
  if (!theDistrict) theDistrict = "";
  if (theDistrict === "00" || theDistrict == '98') theDistrict = "At Large";

  // TODO: Revert Logic for Solo Elems
  return (
    <PanelListElem
      onClick={(e: any) => {
        onClick(e, person);
      }}
      classMod={`gm-person-list-elem ${person.party}`}
      key={person?.identityInfo?.hashID}
      img={<PersonImage person={person} />}
    >
      <div className="gm-person-list-elem-details">
        <span>
          <strong> {person.fullName}</strong>
        </span>
        <span> {person.title} </span>

        <span> {person.party} </span>
      </div>
      <div className="gm-person-list-toggles">
        <CartToggle person={person} />

        <span className="gm-district-span text-right">
          {district ? (theDistrict ? `District: ${theDistrict}` : null) : null}
          {!solo ? (
            <ToggleChevron isOpened={false} classMod="gm-person-chevron" />
          ) : null}
        </span>
      </div>
    </PanelListElem>
  );
};

export default observer(PersonListElem);
