import { computed, observable, action, makeObservable } from "mobx";
import { loadModules } from "esri-loader";

import {
  PANEL_TYPES,
  SEARCH_PANEL_TYPES,
  APTA_SEARCH_TYPES,
  SEARCH_TYPES,
  FEATURE_DATA_TYPES,
} from "./types";
import { geoLayers } from "../../constants/configTS";
import {
  LegislatorTypes,
  FedLeg,
  StateLeg,
  State,
  stateAbbrevToFull,
  memberTypes,
  Committee,
  APTAMember,
  IdentityInfo,
} from "APTAPackage";
import {
  CDDataFactoryPerson,
  genParsedDataSenate,
  genParsedStateLeg,
} from "./PanelHelpers";
import history from "../../components/history";
import { ArcGISIdentityManager } from "@esri/arcgis-rest-request";
import { getLegislatorData } from "APTAPackage";
import { identity } from "lodash";

class PanelStore {
  private authstoreRef: any = undefined;
  private dataStoreRef: any = undefined;

  @observable exploreLayers: any = null;

  @observable legendExpanded: boolean = false;

  @observable activePanel: boolean = true;
  @observable activeView: PANEL_TYPES = PANEL_TYPES.SEARCH;
  @observable prevActiveView: PANEL_TYPES = PANEL_TYPES.SELECT_STATE;

  @observable activeState: string = "";
  @observable activePerson: FedLeg | StateLeg | undefined = undefined;

  @observable.ref layers: any = undefined;

  @observable printMode: boolean = false;
  @observable panelSearch: string = "";
  @observable panelSearchType: SEARCH_PANEL_TYPES =
    SEARCH_PANEL_TYPES.STATE_SEARCH_PANEL;
  @observable panelSearchData: any[] = [];

  @observable APTAMemberSearchStatus: APTA_SEARCH_TYPES =
    APTA_SEARCH_TYPES.APTA_INIT;
  @observable searchStatus: SEARCH_TYPES = SEARCH_TYPES.INIT;

  @observable.ref map: any = undefined;
  @observable.ref mapView: any = undefined;
  @observable graphic: any = undefined;
  @observable layer: any = undefined;

  @observable activeHashId: string | null = null;

  @observable topLayer: LegislatorTypes = LegislatorTypes.STATE_SUM;

  @observable senateOpen: boolean = true;
  @observable houseOpen: boolean = false;
  @observable SLDUOpen: boolean = false;
  @observable SLDLOpen: boolean = false;

  @observable crumbStrings: string[] = ["USA"];
  @observable crumbFunctions: any[] = [null];

  @observable prevCrumbStrings: string[] = ["USA"];
  @observable prevCrumbFunctions: any[] = [null];

  @observable senCommsOpen = true;
  @observable houseCommsOpen = true;

  @observable filteredSenComms: Committee[] = [];
  @observable filteredHouseComms: Committee[] = [];
  @observable filteredJointComms: Committee[] = [];
  @observable activeCommittee: Committee | undefined = undefined;

  @observable activeMember: APTAMember | undefined = undefined;

  @observable featureDataStatus: FEATURE_DATA_TYPES = FEATURE_DATA_TYPES.INIT;
  @observable featureDataLoading: boolean = false;
  @observable featureData: any = undefined;

  @observable mapLoaded: any = false;

  @observable highlight: any = undefined;

  @computed get isSearchView() {
    if (
      this.activeView === PANEL_TYPES.SEARCH ||
      this.activeView === PANEL_TYPES.SELECT_STATE ||
      this.activeView === PANEL_TYPES.STATES
    ) {
      return true;
    }
    return false;
  }

  @computed get getActivePerson() {
    return this.activePerson;
  }

  @action setExploreLayers(newExploreLayers: any) {
    this.exploreLayers = newExploreLayers;
  }

  @action addLayer(layer: any) {
    this.test().then(() => {
      loadModules(["esri/layers/Layer"], {
        version: "4.25",
      }).then(([Layer]) => {
        const newLayer = Layer.fromPortalItem({
          portalItem: {
            id: layer.portalItemId,
          },
        }).then((newLayer: any) => {
          this.map.add(newLayer, 0);
        });
      });
    });
  }

  @action removeLayer(layer: any) {
    this.test().then(() => {
      try {
        const foundLayer = this.map.layers.find(
          (mapLayer: any) => layer.portalItemId === mapLayer.portalItem.id
        );

        this.map.remove(foundLayer);
      } catch (error) {
        return;
      }
    });
  }

  // Store the Previous view in memorry.
  @action registerPrevView = () => {
    this.prevActiveView = this.activeView;
    this.prevCrumbFunctions = this.crumbFunctions;
    this.prevCrumbStrings = this.crumbStrings;
  };

  // Set the Current view Back To the Previous view.
  @action setPrevView = () => {
    this.activeView = this.prevActiveView;
    this.crumbFunctions = this.prevCrumbFunctions;
    this.crumbStrings = this.prevCrumbStrings;
  };

  @action setMapLoaded(newValue: boolean) {
    this.mapLoaded = newValue;
  }

  @action setActiveHashId(newValue: string | null) {
    this.activeHashId = newValue;
  }

  @action setFeatureDataLoading(newValue: boolean) {
    this.featureDataLoading = newValue;
  }

  @action setFeatureData(newValue: any) {
    this.featureData = newValue;
  }

  @action setFeatureStatus(newValue: FEATURE_DATA_TYPES) {
    this.featureDataStatus = newValue;
  }

  @action getFeatureData = async (feature: IdentityInfo) => {
    this.setFeatureDataLoading(true);

    let theFeature = { ...feature };

    // if (
    //   theFeature.stateAbbrev === "DC" &&
    //   theFeature.type === LegislatorTypes.HOUSE
    // ) {
    //   theFeature.district = "98";
    // } else if (
    //   theFeature.stateAbbrev === "PR" &&
    //   theFeature.type === LegislatorTypes.HOUSE
    // ) {
    //   theFeature.district = "1";
    // }

    this.setFeatureStatus(FEATURE_DATA_TYPES.INIT);

    try {
      let session: ArcGISIdentityManager = await this.authstoreRef.GET_esri();

      let res = await getLegislatorData(theFeature, session.token);

      this.setFeatureData(res);
      this.setFeatureStatus(FEATURE_DATA_TYPES.SUCCESS);

      this.setFeatureDataLoading(false);
    } catch (error) {
      this.setFeatureStatus(FEATURE_DATA_TYPES.ERROR);

      this.setFeatureDataLoading(false);
    }
  };

  @action renderLivingAtlas() {
    if (this.activeView === PANEL_TYPES.EXPLORE) {
      return this.setPrevView();
    }

    // const crumbStrings = ["USA", stateFull];
    // const crumbFunctions = [this.renderSelectStatePanel, null];
    // this.crumbStrings = crumbStrings;
    // this.crumbFunctions = crumbFunctions;

    this.registerPrevView();
    this.activePanel = true;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.EXPLORE;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = ["Explore Layers"];
    this.crumbFunctions = [null];
  }

  @action renderCommitteePanel(newActiveCommittee: Committee) {
    // const crumbStrings = ["USA", stateFull];
    // const crumbFunctions = [this.renderSelectStatePanel, null];
    // this.crumbStrings = crumbStrings;
    // this.crumbFunctions = crumbFunctions;

    let commName = newActiveCommittee.committeeName;
    const commLength = commName.length;
    if (commLength > 50) commName = `${commName.slice(0, 50)}...`;

    const crumbStrings = ["Committees", commName];
    const crumbFunctions = [
      () => this.setActiveView(this.prevActiveView),
      null,
    ];

    this.activePanel = true;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.COMMITTEE;
    this.activeCommittee = newActiveCommittee;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = crumbStrings;
    this.crumbFunctions = crumbFunctions;
  }

  @action renderMemberPanel(newActiveMember: APTAMember) {
    // const crumbStrings = ["USA", stateFull];
    // const crumbFunctions = [this.renderSelectStatePanel, null];
    // this.crumbStrings = crumbStrings;
    // this.crumbFunctions = crumbFunctions;

    this.activePanel = true;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.APTAMEMBER;
    this.activeMember = newActiveMember;

    // highlight features based on a query result

    // this.mapView.whenLayerView(treesLayer).then(function(layerView){
    //  var query = treesLayer.createQuery();
    //  query.where = "type = 'Quercus'";
    //  treesLayer.queryFeatures(query).then(function(result){
    //    if (highlight) {
    //      highlight.remove();
    //    }
    //    highlight = layerView.highlight(result.features);
    //  })
    // });

    this.goToGeometry(newActiveMember.geometry, 10);
    let memberLayer = this.mapView.allLayerViews.items.filter((elem: any) => {
      if (elem?.layer?.portalItem?.id === "eb2b7b71eb9d41daa669de04a78b1758")
        return elem;
    });
    memberLayer = memberLayer?.[0]?.layer;

    this.mapView.whenLayerView(memberLayer).then((layerView: any) => {
      var query = memberLayer.createQuery();
      query.where = `OBJECTID = '${newActiveMember.identityInfo.OBJECTID}'`;
      memberLayer.queryFeatures(query).then((result: any) => {
        if (this.highlight) {
          this.highlight.remove();
        }
        this.highlight = layerView.highlight(result.features);
      });
    });
  }

  @action updateSenCommsOpen = (open: boolean) => {
    this.senCommsOpen = open;
  };

  @action updateHouseCommsOpen = (open: boolean) => {
    this.houseCommsOpen = open;
  };

  @action setActiveCommittee = (newActiveCommittee: Committee) => {
    this.activeCommittee = newActiveCommittee;
  };

  @action setLayers = (newLayers: any): void => {
    this.layers = newLayers;
  };

  @action setGraphic = (graphic: any): void => {
    this.graphic = graphic;
  };

  @action setLayer = (layer: any): void => {
    this.layer = layer;
  };

  @action setMap = (map: any, mapView: any) => {
    this.map = map;
    this.mapView = mapView;
  };

  @action setPrintMode = (): void => {
    this.printMode = !this.printMode;
  };

  @action setPrintModeTrueOnLogin = (): void => {
    this.printMode = true;
  };

  @action setPrintModeFalseOnLogin = (): void => {
    this.printMode = false;
  };

  @action setActivePanel = (): void => {
    this.activePanel = !this.activePanel;
  };

  @action setLegendExpanded = (val: boolean): void => {
    this.legendExpanded = val;
  };

  @action setActiveView = (newActiveView: PANEL_TYPES) => {
    this.prevActiveView = this.activeView;
    this.activeView = newActiveView;
  };

  @action getSection = (state: string) => {
    this.activeState = state;
  };
  @action setActiveState = (state: string) => {
    this.activeState = state;
  };
  @action setSectionOpen = (type: LegislatorTypes) => {
    switch (type) {
      case LegislatorTypes.STATE_SUM:
        return null;
      case LegislatorTypes.SENATE:
        return (this.senateOpen = !this.senateOpen);
      case LegislatorTypes.HOUSE:
        return (this.houseOpen = !this.houseOpen);
      case LegislatorTypes.SLDU:
        return (this.SLDUOpen = !this.SLDUOpen);
      case LegislatorTypes.SLDL:
        return (this.SLDLOpen = !this.SLDLOpen);
    }
  };

  @action getComms = () => {
    this.filteredHouseComms = this.dataStoreRef?.houseComms;
    this.filteredSenComms = this.dataStoreRef?.senComms;
    this.filteredJointComms = this.dataStoreRef?.jointComms;
  };

  @action setCrumb = (stringArr: string[], funcArr: any[]) => {
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = stringArr;
    this.crumbFunctions = funcArr;
  };

  setLocation(nextLoc: string) {
    let locTest = history.location.pathname.slice(0, 4);
    if (locTest === "/map") history.push(nextLoc);
  }

  @action renderPerson = async (
    person: FedLeg | StateLeg | State,
    geometry: any
  ) => {
    let locTest = history.location.pathname.slice(0, 4);
    let flag = false;
    if (locTest !== "/map" && locTest !== "/dev") {
      history.push("/map");
      if (this.mapView === undefined) await this.waitFunc();
    }

    const { type, stateAbbrev } = person.identityInfo;
    switch (type) {
      case LegislatorTypes.STATE_SUM:
        return this.renderStatePanel(stateAbbrev);
      case LegislatorTypes.SENATE:
        return this.renderSenatorPersonPanel(person as FedLeg, geometry);
      case LegislatorTypes.HOUSE:
        return this.renderCDPersonPanel(person as FedLeg, geometry);
      case LegislatorTypes.SLDU:
        return this.renderStateLegPersonPanel(person as StateLeg, geometry);
      case LegislatorTypes.SLDL:
        return this.renderStateLegPersonPanel(person as StateLeg, geometry);
      default:
        break;
    }
  };

  // Need to reset all info when going here.
  @action renderSelectStatePanel = (activePanel?: boolean) => {
    if (activePanel === undefined) activePanel = this.activePanel;

    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.SELECT_STATE;
    this.activePanel = activePanel;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = ["USA"];
    this.crumbFunctions = [null];

    this.setLocation("/map/");
  };

  @action renderStatePanel(stateAbbrev: string, geometry: any = undefined) {
    const stateFull = stateAbbrevToFull[stateAbbrev];

    const crumbStrings = ["USA", stateFull];
    const crumbFunctions = [this.renderSelectStatePanel, null];

    this.activePanel = true;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.STATES;
    this.activeState = stateAbbrev;
    this.activePerson = undefined;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = crumbStrings;
    this.crumbFunctions = crumbFunctions;

    // Get real hash for state
    const hashID = `${LegislatorTypes.STATE_SUM}-${stateAbbrev}---`;
    const subPerson = this.dataStoreRef.getPerson(hashID, false);

    this.setActiveHashId(hashID);
    this.getFeatureData(subPerson.identityInfo);

    geometry !== undefined
      ? this.goToFeature(geometry)
      : this.goToFeature(subPerson);

    this.setTopLayer(LegislatorTypes.SENATE);
    this.setLocation(`/map/${hashID}`);

    // testLocation(`/map/${stateAbbrev}`);
  }

  // @action renderCommPanel = (
  //   comm: ICommittee
  // ) => {
  //   const parsedData = genParsedDataSenate(person);
  //   this.getFeatureData(person.identityInfo);

  //   const crumbStrings = [
  //     "Committees",
  //     comm.chamber,
  //     comm.name
  //   ];
  //   const crumbFunctions = [
  //     () => this.renderSelectStatePanel(),
  //     () => this.renderStatePanel(person?.identityInfo?.stateAbbrev),
  //     () => this.renderStatePanel(person?.identityInfo?.stateAbbrev),
  //     null,
  //   ];

  //   this.activePanel = true;
  //   this.activeState = person?.identityInfo?.stateAbbrev;
  //   this.prevActiveView = this.activeView;
  //   this.activeView = PANEL_TYPES.PERSON;
  //   this.activePerson = person;
  //   this.prevCrumbStrings = [...this.crumbStrings];
  //   this.prevCrumbFunctions = [...this.crumbFunctions];
  //   this.crumbStrings = crumbStrings;
  //   this.crumbFunctions = crumbFunctions;

  //   //getAllInfo2(parsedData);

  //   geometry !== undefined
  //     ? this.goToFeature(geometry)
  //     : this.goToFeature(person);
  //   this.setTopLayer(LegislatorTypes.SENATE);
  //   this.setLocation(`/map/${person.identityInfo.hashID}`);
  // };

  @action renderSenatorPersonPanel = (
    person: FedLeg,
    geometry: any = undefined
  ) => {
    const parsedData = genParsedDataSenate(person);

    this.getFeatureData(person.identityInfo);

    const crumbStrings = [
      "USA",
      person?.identityInfo?.stateFull as string,
      "Senators",
      person?.fullName as string,
    ];
    const crumbFunctions = [
      () => this.renderSelectStatePanel(),
      () => this.renderStatePanel(person?.identityInfo?.stateAbbrev),
      () => this.renderStatePanel(person?.identityInfo?.stateAbbrev),
      null,
    ];

    this.activePanel = true;
    this.activeState = person?.identityInfo?.stateAbbrev;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.PERSON;
    this.activePerson = person;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = crumbStrings;
    this.crumbFunctions = crumbFunctions;

    this.setActiveHashId(person.identityInfo.hashID);
    //getAllInfo2(parsedData);

    geometry !== undefined
      ? this.goToFeature(geometry)
      : this.goToFeature(person);
    this.setTopLayer(LegislatorTypes.SENATE);
    this.setLocation(`/map/${person.identityInfo.hashID}`);
  };

  @action renderCDPersonPanel(person: FedLeg, geometry: any = undefined) {
    // TODO: Add Special Case To Parsing for At Large Districts.
    // Special Case to handle DC and at-large districts.

    const { stateAbbrev, stateFull, district, geoid } = person.identityInfo;
    const parsedData = CDDataFactoryPerson(person);

    let key = district;
    // if (person.identityInfo.stateAbbrev === "DC") {
    //   key = "98";
    //   let identityInfo = { ...person.identityInfo };
    //   identityInfo.district = "98";
    //   this.getFeatureData(identityInfo);
    // } else {
    this.getFeatureData(person.identityInfo);
    // }

    const crumbStrings: string[] = [
      "USA",
      stateFull,
      `${stateAbbrev}-${key === "00" || key === "98" ? "At Large" : key}`,
      person.fullName as string,
    ];

    const crumbFunctions = [
      () => this.renderSelectStatePanel(),
      () => this.renderStatePanel(stateAbbrev),
      () => this.renderStatePanel(stateAbbrev),
      null,
    ];

    this.activeHashId = null;
    this.activePanel = true;
    this.activeState = stateAbbrev;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.PERSON;
    this.activePerson = person;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = crumbStrings;
    this.crumbFunctions = crumbFunctions;
    this.setActiveHashId(person.identityInfo.hashID);
    //  getAllInfo(parsedData);

    geometry !== undefined
      ? this.goToFeature(geometry)
      : this.goToFeature(person);
    this.setTopLayer(LegislatorTypes.HOUSE);
    this.setLocation(`/map/${person.identityInfo.hashID}`);
  }

  // // TODO: Better Handle Multipule State Legs per district
  @action renderStateLegPersonPanel = (
    person: StateLeg,
    geometry: any = undefined
  ) => {
    const { type, district, stateAbbrev, builtID } = person.identityInfo;
    let parsedData = genParsedStateLeg(person);
    this.getFeatureData(person.identityInfo);

    let crumbStrings = [
      "USA",
      person.identityInfo.stateFull,
      `${person?.identityInfo?.chamber?.toUpperCase()}`,
      `${person?.identityInfo?.chamber?.toUpperCase()}-${
        person?.identityInfo?.district
      }`,
    ];

    let crumbFunctions = [
      () => this.renderSelectStatePanel(),
      () => this.renderStatePanel(person?.identityInfo?.stateAbbrev),
      () => this.renderStatePanel(person?.identityInfo?.stateAbbrev),
      null,
    ];

    this.activePanel = true;
    this.activeState = person?.identityInfo?.stateAbbrev;
    this.prevActiveView = this.activeView;
    this.activeView = PANEL_TYPES.PERSON;
    this.activePerson = person;
    this.prevCrumbStrings = [...this.crumbStrings];
    this.prevCrumbFunctions = [...this.crumbFunctions];
    this.crumbStrings = crumbStrings;
    this.crumbFunctions = crumbFunctions;
    this.setActiveHashId(person.identityInfo.hashID);
    //  getAllInfo(parsedData);

    // Special logic for getting parsed data.
    if (district === "At-Large" || district === "Chairman") {
      parsedData = { ...parsedData, type: memberTypes.STATE };
    }

    geometry !== undefined
      ? this.goToFeature(geometry)
      : this.goToFeature(person);
    person.identityInfo.type === LegislatorTypes.SLDL
      ? this.setTopLayer(LegislatorTypes.SLDL)
      : this.setTopLayer(LegislatorTypes.SLDU);

    this.setLocation(`/map/${person.identityInfo.hashID}`);
  };

  findLayerType(type: LegislatorTypes) {
    const theLayerId = geoLayers[type];
    return this.findLayer(theLayerId);
  }

  findLayer(theLayerId: string) {
    return this.mapView.layerViews.find((layerView: any) => {
      if (layerView?.layer?.portalItem?.id === theLayerId) return layerView;
    });
  }

  waitFunc = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(undefined);
      }, 1000);
    });
  };

  test() {
    return new Promise(async (resolve) => {
      let load = this.mapLoaded;
      while (load === false) {
        await this.waitFunc();
        load = this.mapLoaded;
      }

      resolve(undefined);
    });
  }

  @action setTopLayer2(layer: LegislatorTypes) {
    this.topLayer = layer;
  }

  @action setTopLayer(layer: LegislatorTypes) {
    this.test().then(() => {
      if (layer !== this.topLayer) {
        this.mapView.graphics.removeAll(); // make sure to remmove previous highlighted feature

        this.setTopLayer2(layer);
        const theLayerId = geoLayers[layer];
        let geoLayersArr = Object.values(geoLayers);
        let filteredLayersArr = geoLayersArr.filter((id) => id !== theLayerId);

        filteredLayersArr.forEach((id: string) => {
          const theLayerView = this.findLayer(id);

          if (theLayerView !== undefined) theLayerView.visible = false;
        });

        const theLayerView = this.findLayer(theLayerId);

        if (theLayerView !== undefined) {
          theLayerView.visible = true;
        }
      }
    });
  }

  @action goToFeature = (data: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      this.test().then(() => {
        let theGeom = data;

        if (this.isLeg(data)) {
          this.getGeometry(data)
            .then((geom) => {
              this.goToGeometry(geom)
                .then(() => resolve())
                .catch((error) => reject(error));
            })
            .catch((error) => {
              theGeom = undefined;

              reject(error);
            });
        } else {
          this.goToGeometry(theGeom)
            .then(() => resolve())
            .catch((error) => reject(error));
        }
      });
    });
  };

  isLeg(leg: (FedLeg | StateLeg) | any): leg is FedLeg {
    return (leg as FedLeg)?.identityInfo?.type !== undefined;
  }

  goToGeometry = (geometry: any, zoom?: any): Promise<void> => {
    return new Promise((resolve, reject) => {
      loadModules(["esri/Graphic"], { version: "4.25" }).then(([Graphic]) => {
        if (geometry?.type === "polygon") {
          // TODO: Define this Symbol.
          const symbol = {
            type: "simple-fill",
            color: [51, 51, 204, 0.2],
            style: "solid",
            outline: {
              color: "white",
              width: 1,
            },
          };

          const theGraphic = new Graphic({ geometry, symbol });

          this?.mapView?.graphics?.removeAll(); // make sure to remmove previous highlighted feature
          this?.mapView?.graphics?.add(theGraphic);
        }

        if (!geometry) {
          return "No Geometry";
        }

        zoom
          ? this?.mapView
              ?.goTo({ target: geometry, zoom })
              ?.then(() => {
                resolve();
              })
              ?.catch((error: Error) => {
                reject(error);
              })
          : this?.mapView
              ?.goTo(geometry)
              ?.then(() => {
                resolve();
              })
              ?.catch((error: Error) => {
                reject(error);
              });
      });
    });
  };

  // When Navigating via the panel we need to get the geometry for the zoom and highlight.
  getGeometry = (data: FedLeg | StateLeg) => {
    return new Promise((resolve, reject) => {
      const type = data?.identityInfo?.type;
      const chamber = data?.identityInfo.chamber as string;

      if (type === undefined) return;

      let key = `${type}_CORE`;
      const theLayer = this?.layers?.[key];

      if (theLayer === undefined) return;
      const query = theLayer.createQuery();
      query.returnGeometry = true;
      query.outFields = ["*"];

      switch (type) {
        case LegislatorTypes.STATE_SUM: {
          let senator: FedLeg = data as FedLeg;
          query.where = `Abbrev = '${senator.identityInfo.stateAbbrev}'`;
          break;
        }
        case LegislatorTypes.SENATE: {
          let senator: FedLeg = data as FedLeg;
          query.where = `Abbrev = '${senator.identityInfo.stateAbbrev}'`;
          break;
        }
        case LegislatorTypes.HOUSE: {
          let rep: FedLeg = data as FedLeg;

          query.where = `CD_ID = '${rep.identityInfo.geoid}'`;
          break;
        }
        case LegislatorTypes.SLDU: {
          let rep: StateLeg = data as StateLeg;
          query.where = `SLDU_ID = '${rep.identityInfo.builtID}'`;
          break;
        }
        case LegislatorTypes.SLDL: {
          let rep: StateLeg = data as StateLeg;
          const state = rep?.identityInfo?.stateAbbrev;
          const district = rep?.identityInfo?.district;

          if (chamber?.toLowerCase() === "legislature") {
            query.where = `lower(SLDL_ID) = '${state}-upper-${district}'`;
            break;
          }

          query.where = `lower(SLDL_ID) = '${rep.identityInfo.builtID}'`;
          break;
        }
        default: {
          break;
        }
      }

      theLayer
        .queryFeatures(query)
        .then((results: any) => {
          resolve(results?.features?.[0]?.geometry);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  };

  // @action renderSingleBodyPanel = (stateAbbrev: string, type: LegislatorTypes) => {
  //   const stateFull = stateAbbrevToFull[stateAbbrev];
  //   const queryData = { stateAbbrev, stateFull, type: memberTypes.STATE };

  // //  let activeStateLevelData = buildActiveStateLevelData(stateAbbrev, type);

  //   const crumbStrings = ["USA", stateFull, type];
  //   const crumbFunctions = [
  //     () => this.renderSelectStatePanel(),
  //     () => this.renderStatePanel(stateAbbrev),
  //     null,
  //   ];

  //   // const personType =
  //   //   type === mapLayers.STATE || type === mapLayers.CD
  //   //     ? personTypes.FED
  //   //     : personTypes.STATE;

  //   const viewState = {
  //     activeView: types.STATES,
  //     activePanel: true,
  //     dropdownToggles: selectDropdownToggles[type],
  //     activeStateLevelData,
  //     activePersonType: personType,
  //     crumbStrings,
  //     crumbFunctions,
  //   };

  //   getAllInfo(queryData);
  //   updateViewState(viewState);
  //   selectGoTo[type](stateAbbrev);
  // }

  // function renderSenatorPanel(stateAbbrev: string) {
  //   renderSingleBodyPanel(stateAbbrev, mapLayers.STATE, "Senators");
  //   testLocation(`/map/${stateAbbrev}/${urlPaths.SENATE}`);
  // }

  // function renderCDPanel(stateAbbrev: string) {
  //   renderSingleBodyPanel(stateAbbrev, mapLayers.CD, "Congress");
  //   testLocation(`/map/${stateAbbrev}/${urlPaths.CD}`);
  // }

  // function renderSLDLPanel(stateAbbrev: string) {
  //   renderSingleBodyPanel(stateAbbrev, mapLayers.SLDL, "SLDL");
  //   testLocation(`/map/${stateAbbrev}/${urlPaths.SLDL}`);
  // }

  // function renderSLDUPanel(stateAbbrev: string) {
  //   renderSingleBodyPanel(stateAbbrev, mapLayers.SLDU, "SLDU");
  //   testLocation(`/map/${stateAbbrev}/${urlPaths.SLDU}`);
  // }

  constructor(dataStore: any, authStore: any) {
    makeObservable(this);
    this.dataStoreRef = dataStore;
    this.authstoreRef = authStore;
    // initAPTALayer()
    //   .then((theLayer) => {
    //     this.APTAMembers = theLayer;
    //   })
    //   .catch((error) => {
    //     this.APTAMembers = undefined;
    //   });
  }
}

export default PanelStore;
