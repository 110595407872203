import {
  LegislatorMap,
  FedLeg,
  StateLeg,
  Committee,
} from "APTAPackage";
import * as fullData from "../../public/data2/fullData.json";
import * as houseCommittees from "../../public/data2/house-committees.json" ;
import * as senateCommittees from "../../public/data2/senate-committees.json";
import * as jointCommittees from '../../public/data2/joint-committees.json';

//@ts-ignore
import TrieMaster from "../Types/Tries/TrieMaster";
import { observable, makeObservable, action, computed } from "mobx";
import { compareCommittee } from "../services/Committees";

export default class DataStore {
  @observable loaded: boolean;
  Tries: TrieMaster | undefined;
  data: LegislatorMap | undefined;
  senComms: Committee[] = [];
  houseComms: Committee[] = [];
  jointComms: Committee[] = [];

  constructor() {
    this.loaded = false;
    this.Tries = undefined;
    this.data = undefined;
    makeObservable(this);
  }

  getPerson(hashID: string) {
    
    if (this.data !== undefined && hashID !== undefined) {
      return this.data.getPerson(hashID);
    }
    return undefined;
  }

  getPerson_no_external(hashID: string) {
    if (this.data !== undefined && hashID !== undefined) {
      return this.data.getPerson_no_external(hashID);
    }
    return undefined;
  }

  getFlat(state: string) {
    if (this.data !== undefined) {
      return this.data.getFlat(state);
    }
    return undefined;
  }

  @computed get stateAbbrevs() {
    if (this?.data?.map !== undefined) return Object.keys(this.data?.map);
    return undefined;
  }

  @computed get getFlatFed() {
    const stateAbbrevs = this.stateAbbrevs;
    let flatList: FedLeg[] = [];
    stateAbbrevs?.forEach((abbrev) => {
      let data = this.getFlat(abbrev);
      if (data !== undefined) {
        const { flatSenate, flatHouse } = data;
        flatList = [...flatList, ...flatSenate, ...flatHouse];
      }
    });
    return flatList;
  }

  @action loadData = (): any => {
    //@ts-ignore
    const allStateObj = new LegislatorMap(fullData);
    const { map } = allStateObj;
    // Gen Trie datastructure.
    const Tries = new TrieMaster();
    // Get All State Keys
    const stateKeys: string[] = Object.keys(map);

    stateKeys.forEach((state: string) => {
      const flatState = allStateObj.getFlat(state);

      Tries.setStateVariations(state);

      flatState.flatSenate.forEach((senator: FedLeg) => {
        //@ts-ignore
        if(senator.firstName != null && senator.is_missing != true){
       
        Tries.setNameVariations(senator);
        }
      });

      flatState.flatHouse.forEach((houseRep: FedLeg) => {
       //@ts-ignore
        if(houseRep.is_missing != true){
        Tries.setNameVariations(houseRep);
        Tries.setHouseVariations(houseRep);
        }
      });

      flatState.flatSLDL.forEach((SLDLRep: StateLeg) => {
        Tries.setNameVariations(SLDLRep);
        Tries.setChamberVariations(SLDLRep);
      });

      flatState.flatSLDU.forEach((SLDURep: StateLeg) => {
        Tries.setNameVariations(SLDURep);
        Tries.setChamberVariations(SLDURep);
      });
    });

    this.Tries = Tries;
    this.data = allStateObj;

   

    //@ts-ignore
    const sortedHouse = houseCommittees.sort(compareCommittee);
    //@ts-ignore
    const sortedSen = senateCommittees.sort(compareCommittee)
    //@ts-ignore
    const sortedJoint = jointCommittees.sort(compareCommittee);

    //@ts-ignore
    this.houseComms = sortedHouse;
    //@ts-ignore
    this.senComms = sortedSen;
        //@ts-ignore
    this.jointComms = sortedJoint;
    this.loaded = true;
    // resolve();
  
  };
}
