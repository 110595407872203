import { useEffect, useState } from "react";
import { T_attributes } from "./types";
import { getGrantsRequest } from "./getGrantsRequest";
import { grantURLs } from "../../constants/configTS";

type T_props = {
  state?: string;
  token: string | null;
  activerPersonIdentity?: any;
  hashId?: any;
  pathName: string;
};

const stateURL = grantURLs.STATE;
const cdURL = grantURLs.CD;
const slduURL = grantURLs.SLDU;
const sldlURL = grantURLs.SLDL;

export const useGetGrants = ({
  state,
  token,
  hashId,
  pathName,
  activerPersonIdentity,
}: T_props) => {
  const [data, setData] = useState<T_attributes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const splitHashId = hashId?.split("-");

  const useThisURL = () => {
    const isHouse = pathName.includes("house") || hashId?.includes("house");
    const isUpperChamb = pathName.includes("sldu") || hashId?.includes("sldu");
    const isLowerChamb = pathName.includes("sldl") || hashId?.includes("sldl");
    // const isFed = pathName.includes("senate") || hashId?.includes("senate");

    if (isHouse) {
      return cdURL;
    }
    if (isUpperChamb) {
      return slduURL;
    }
    if (isLowerChamb) {
      return sldlURL;
    }
    return stateURL;
  };

  const createOtherParams = () => {
    const isHouse = pathName.includes("house") || hashId?.includes("house");
    const isUpperChamb = pathName.includes("sldu") || hashId?.includes("sldu");
    const isLowerChamb = pathName.includes("sldl") || hashId?.includes("sldl");



    if (isHouse) {
      const district = activerPersonIdentity?.geoid;
      return `CD_ID = '${district}'`;
    }
    if (isUpperChamb) {
      
      let district = activerPersonIdentity?.district || splitHashId?.[2];
      if(district == 'At Large' || district == 'Chairman') district = 'At-Large'
      return `SLDU_ID = '${state}-upper-${district}'`;
      
    }
    if (isLowerChamb) {
      let district = activerPersonIdentity?.district || splitHashId?.[2];
        if(district == 'At Large' || district == 'Chairman') district = 'At-Large'
      return `SLDL_ID = '${state}-lower-${district}'`;
    }

    return undefined;
  };

  useEffect(() => {
    if (token != null) {
      setIsLoading(true);



      getGrantsRequest({
        url: useThisURL(),
        state,
        token,
        otherWhere: createOtherParams(),
      })
        .then((res) => {

          setData(res);
          setIsSuccess(true);
        })
        .then(() => {
          setIsLoading(false);
        })
        .catch((err: any) => {
          setError(err);
          setIsSuccess(false);
          setIsError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [state, token]);

  return { data, isLoading, isError, error, isSuccess };
};
