import { FedLeg, LegislatorTypes, StateLeg } from "APTAPackage";
import React, { useEffect, useMemo, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface T_props {
  person: FedLeg | StateLeg;
}
const CDN_URL = process.env.REACT_APP_CDN_URL
const errorUrl = "/images/icons/blankPerson.png";

export const PersonImage: React.FC<T_props> = ({ person }) => {

  const [urlIndex, setUrlIndex] = useState(0);

  const urls = useMemo(() => {
    const id = person?.API_ID;
    const type = person?.identityInfo?.type;
    if (type == null || id == null) return [errorUrl];

    switch (type) {

        case LegislatorTypes.SENATE: {
  
          const photoFolder = id[0] != null ? id[0]: 'A';
          const s3Url = `${CDN_URL}/senate/${id}.jpg`;
          const govUrl = `https://bioguide.congress.gov/bioguide/photo/${photoFolder}/${id}.jpg`
          const fallbackUrl = person.image as string;
          return [s3Url, govUrl, fallbackUrl, errorUrl];
        }
        case LegislatorTypes.HOUSE: {
          const photoFolder = id[0] != null ? id[0]: 'A';
          const s3Url = `${CDN_URL}/house/${id}.jpg`;
          const govUrl1 = `https://clerk.house.gov/images/members/${id}.jpg`
          const govUrl2 = `https://bioguide.congress.gov/bioguide/photo/${photoFolder}/${id}.jpg`
          const fallbackUrl = person.image as string;
          return [s3Url, govUrl1, govUrl2, fallbackUrl, errorUrl];
        }

      case LegislatorTypes.SLDL:
      case LegislatorTypes.SLDU: {
        const url = person.image as string;

        return [url, errorUrl];
      }
      default:
        return [errorUrl];
    }
  }, [person]);

  const url = urls[urlIndex];

  useEffect(() => {
    if (url == null) setUrlIndex(urlIndex + 1);
  }, [url]);

  return (
    <LazyLoadImage
      className={"gm-person-list-elem-img"}
      onError={(e) => {
        //@ts-expect-error
        e.target.onError = null;
        setUrlIndex(urlIndex + 1);
      }}
      src={url}
      alt={`${person.fullName} image`}
    />
  );
};
