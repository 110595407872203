import React from "react";
import { aptaMembersGraph, transitSystemGraph } from "../../../../../constants/config";
import { HomeHeader } from "../HomeHeader";
import { Space } from "../Space";
import { HomeText } from "../HomeText";
import { BarGraphRefactor } from "../../../../graphs/BarGraph/BarGraphRefactor";

const text = {
  membersBold:
    "APTA has built strong relationships with key partners to yield positive results for public transportation.",
  membersRest: `With over 1,500 members in 3000+ locations, APTA is the
  primary advocate in Washington, DC for the public
  transportation industry. Our organization works directly with
  Congress, the White House, and Federal Agencies to continue to
  grow the federal investment in public transportation
  infrastructure.`,
  transitBold: `APTA members represent more than 90 percent of transit
  riders in North America.`,
  transitRest: `APTA’s policies for federal legislative and regulatory issues
  are developed by a wide cross-section of our membership and
  represent the consensus of the transit industry. We
  proactively engage key policy makers on a wide range of
  legislative and regulatory matters impacting public
  transportation and high-speed and intercity rail.`,
};

export const MembersHome = () => {
  return (
    <div className="row">
      <div className="col">
        <HomeHeader title="Member Facilities" id="members_section" />

        <div className="row">
          <div className="col-md-7 gm-border-right">
            <BarGraphRefactor {...aptaMembersGraph} />
          </div>

          <div className="col-md my-auto">
            <HomeText bold={text.membersBold} rest={text.membersRest} />
          </div>
        </div>

        {/* <Space isHR={false} /> */}

        <div className="row pt-3">
          <div className="col-md gm-border-right my-auto">
            <HomeText bold={text.transitBold} rest={text.transitRest} />
          </div>
          <div className="col-md-6">
            <BarGraphRefactor {...transitSystemGraph} />
          </div>
        </div>
      </div>
    </div>
  );
};
